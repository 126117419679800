import { createFileRoute, notFound, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"
import { isUUIDFormat } from "@/lib/utils"
import type { CustomTemplate, Template } from "@/types"

export const Route = createFileRoute("/_authlayout/_header/templates/$id/")({
  staticData: {
    title: "Template",
    onSpaceChange: () =>
      redirect({
        to: "/templates",
        throw: true
      })
  },
  beforeLoad: async ({ context: { authClient }, preload }) => {
    // Skip if we're preloading
    if (preload) return

    const user = await authClient.getUser()
    if (!user) {
      throw new Error("You must be logged in to access this page.")
    }

    // Block access if minor
    if (user["http://arduino.cc/is_minor"] === true) {
      redirect({
        to: "/",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage, headManager }, params }) => {
    const space = spaceStorage.getSpace()

    let template: Template | CustomTemplate

    queryClient.ensureQueryData(query.other.boards)

    try {
      if (!isUUIDFormat(params.id)) {
        template = await queryClient.ensureQueryData(query["iot-templates"].single(params.id))
      } else {
        template = await queryClient.ensureQueryData(query.templates.single(params.id, space))
      }

      headManager.push({
        title: `${template.name} | Arduino Cloud`
      })
    } catch (e) {
      console.error(e) // Log the error, but don't show it to the user.
      notFound({
        throw: true
      })
    }
  }
})
