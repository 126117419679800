import { createRouter, Navigate } from "@tanstack/react-router"

import { NoneLayout } from "@/components/layouts/none"
import { PageLoader } from "@/components/ui/PageLoader"
import { ga4Emitter } from "@/lib/ga4Emitter"
import { AuthClientInstance, headManager, queryClient, SpaceStorageInstance } from "@/lib/services/instances"
import { CloudContext } from "@/providers/CloudContext"

// Import the generated route tree
import { routeTree } from "./routeTree.gen"

const context = {
  queryClient,
  spaceStorage: SpaceStorageInstance,
  authClient: AuthClientInstance,
  headManager
}
type RouterContext = typeof context

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
    spaceStorage: SpaceStorageInstance,
    authClient: AuthClientInstance,
    headManager
  },
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  // ? Should we have instant pending transitions? This is a UX decision.
  defaultPendingMinMs: 0,
  defaultPendingMs: 0,
  // Default components
  // defaultErrorComponent: error => <ErrorBoundary error={error} />,
  defaultNotFoundComponent: () => <Navigate to="/" replace />,
  defaultPendingComponent: () => (
    <NoneLayout style={{ backgroundColor: "white" }}>
      <PageLoader />
    </NoneLayout>
  ),
  // Wrap the router with the CloudContext, so we can invalidate the router when the space changes.
  InnerWrap: ({ children }) => <CloudContext>{children}</CloudContext>
})

// Update the document title and send a pageview event when the routes are
router.subscribe("onResolved", () => {
  // Get the static data from the last match with static data
  const matchWithTitle = [...router.state.matches].reverse().find(d => d.staticData)
  const routeTitle = matchWithTitle?.staticData?.title
    ? `${matchWithTitle?.staticData?.title} | Arduino Cloud`
    : "Arduino Cloud"

  // Update the document title and fire a pageview events
  headManager.push({ title: routeTitle })
  ga4Emitter({ type: "PAGEVIEW", payload: { page_title: routeTitle } })
})

// Export the router and the context type
export { router }
export type { RouterContext }
