import { createFileRoute, notFound, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"

export const Route = createFileRoute("/_authlayout/_header/templates/$id/edit/")({
  staticData: {
    title: "Edit Template",
    onSpaceChange: () =>
      redirect({
        to: "/templates",
        throw: true
      })
  },
  beforeLoad: async ({ context: { authClient }, preload }) => {
    // Skip if we're preloading
    if (preload) return

    const user = await authClient.getUser()
    if (!user) {
      throw new Error("You must be logged in to access this page.")
    }

    // Block access if minor
    if (user["http://arduino.cc/is_minor"] === true) {
      redirect({
        to: "/",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage }, params }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.other.boards)

    try {
      queryClient.ensureQueryData(query.templates.single(params.id, space))
    } catch (e) {
      console.error(e) // Log the error, but don't show it to the user.
      notFound({
        throw: true
      })
    }
  }
})
