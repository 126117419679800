import { MY_CLOUD_SPACE } from "@bcmi-labs/cloud-sidebar"
import { createFileRoute, notFound, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"

export const Route = createFileRoute("/_authlayout/_header/devices/$id")({
  staticData: {
    title: "Device Page",
    onSpaceChange: () =>
      redirect({
        to: "/devices",
        throw: true
      })
  },
  beforeLoad: async ({ context: { authClient }, preload }) => {
    // Skip if we're preloading
    if (preload) return

    const user = await authClient.getUser()
    if (!user) {
      throw new Error("You must be logged in to access this page.")
    }

    // Block access if minor
    if (user["http://arduino.cc/is_minor"] === true) {
      redirect({
        to: "/",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage, headManager }, params }) => {
    const space = spaceStorage.getSpace()

    try {
      const deviceData = await queryClient.ensureQueryData({ ...query.device.single.info(space, params.id) })
      // Try prefetching the OTA list query, but don't wait for it or error out.
      queryClient.prefetchQuery(query.device.single.otaList(space, params.id))

      if (deviceData.organization_id !== space && space !== MY_CLOUD_SPACE.id) notFound({ throw: true })

      headManager.push({
        title: `${deviceData.name} | Arduino Cloud`
      })
    } catch (e) {
      // Whatever happens, we want to throw a 404.
      notFound({ throw: true })
    }
  }
})
