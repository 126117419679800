/* eslint-disable cloud-website/no-anchor */
import type { ComponentPropsWithoutRef } from "react"
import { forwardRef } from "react"

import { getChromebookFlag } from "@/lib/utils/detect"

const DISALLOWED_ORIGINS = [import.meta.env.VITE_STORE, `${import.meta.env.VITE_CLOUD_URL}/plans`] as string[]

type ExternalLinkProps = ComponentPropsWithoutRef<"a"> & {
  disabled?: boolean
}

/** An external anchor with rel="noopener noreferrer" already in. Also slottable. */
export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>(({ target, children, ...rest }, ref) => {
  // If we're running on a chromebook app, disable links incoming from disallowed sources.
  const isDisabled = getChromebookFlag()
    ? DISALLOWED_ORIGINS.some(origin => rest.href?.startsWith(origin)) || rest.disabled
    : rest.disabled

  if (isDisabled) {
    return (
      // @ts-expect-error - We're disabling the anchor, so we need to prevent the default behavior in case this is composed with a button.
      <span {...rest} ref={ref} onClick={e => e.preventDefault()} disabled data-disabled>
        {children}
      </span>
    )
  }

  // Check if the current origin matches the target origin. If so, set the target to "_self".
  return (
    <a
      target={target || (rest.href?.startsWith(window.location.origin) ? "_self" : "_blank")}
      rel="noopener noreferrer"
      {...rest}
      ref={ref}>
      {children}
    </a>
  )
})

ExternalLink.displayName = "ExternalLink"
