import { createFileRoute, redirect } from "@tanstack/react-router"

import { query } from "@/api/query"

export const Route = createFileRoute("/_authlayout/_header/templates/new/")({
  staticData: {
    title: "New Template"
  },
  beforeLoad: async ({ context: { authClient }, preload }) => {
    // Skip if we're preloading
    if (preload) return

    const user = await authClient.getUser()
    if (!user) {
      throw new Error("You must be logged in to access this page.")
    }

    // Block access if minor
    if (user["http://arduino.cc/is_minor"] === true) {
      redirect({
        to: "/",
        throw: true
      })
    }
  },
  loader: async ({ context: { queryClient, spaceStorage } }) => {
    const space = spaceStorage.getSpace()

    queryClient.ensureQueryData(query.thing.list(space))
    queryClient.ensureQueryData(query.device.list(space))
    queryClient.ensureQueryData(query.sketch.list(space))
    queryClient.ensureQueryData(query.dashboard.list(space))
    queryClient.ensureQueryData(query.trigger.list(space))
    queryClient.ensureQueryData(query.other.property_types)
  }
})
